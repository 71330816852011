import styles from './header-mobile.css?module'
import { Component, VueComponent, VNode, Prop } from '~/types/vue-ts-component'

import { AppInput } from '~/components/UI/ui-kit/input'
import { Container } from '~/components/layout/common/container'
import { ProductRepositoryInterface } from '~/abstracts/repository'
import { ProductSearch, minSearchText } from '~/components/layout/header/types'
import { container } from '~/config/invercify'
import DiTypes from '~/config/DiTypes'
import { debounce } from '~/decorators/debounce'
import { COMPANY_CONTACTS } from '~/config/company-contacts'

interface Props {
  menuVisible: boolean
  toggleMobileMenu: () => void
  closeAllPopups: () => void
}

@Component
export class HeaderMobile extends VueComponent<Props> {
  @Prop() readonly menuVisible!: Props['menuVisible']
  @Prop() readonly toggleMobileMenu!: Props['toggleMobileMenu']
  @Prop() readonly closeAllPopups!: Props['closeAllPopups']

  private repository!: ProductRepositoryInterface
  private hints: ProductSearch[] = []

  searchText: string | null = null
  dropdownActive = false

  public clearSearchState(): void {
    this.searchText = null
    this.hints = []
  }

  @debounce(300)
  async textUpdate(text: string) {
    this.searchText = text
    if (text.length < minSearchText) {
      this.hints = []
      return
    }
    this.hints = await this.repository.getProductSearchHints(text)
  }

  async redirectToSearchPage(): Promise<void> {
    if (!this.searchText || this.searchText.length < minSearchText) return
    await this.$router.push({
      path: '/search',
      query: {
        text: this.searchText,
      },
    })
    this.clearSearchState()
  }

  handleLogoClick(e: MouseEvent) {
    e.preventDefault()
    this.closeAllPopups()
    this.$router.push('/')
  }

  async handleHintClick(e: MouseEvent, slug: string): Promise<void> {
    e.preventDefault()
    await this.$router.push(`/details/${slug}`)
  }

  created(): void {
    this.repository = container.get<ProductRepositoryInterface>(
      DiTypes.PRODUCT_REPOSITORY
    )
  }

  render(): VNode {
    return (
      <Container class={styles.headerMobile}>
        <div class={styles.headerContainer}>
          <img
            src={require(`~/assets/icons/${
              this.menuVisible ? 'close-but' : 'burger-menu'
            }.svg`)}
            onclick={this.toggleMobileMenu}
          />
          <a href="/" onClick={this.handleLogoClick}>
            <img class={styles.logo} src={require('~/assets/icons/logo.svg')} />
          </a>
          <a href={`tel:${COMPANY_CONTACTS.phone.mobile}`}>
            <img src={require('~/assets/icons/phone.svg')} />
          </a>
        </div>
        <AppInput
          text={this.searchText}
          class={styles.search}
          icon={require('~/assets/icons/loop.svg')}
          placeholder="Ночь, улица, фонарь, аптека..."
          textUpdate={this.textUpdate}
          whenEnterPressed={this.redirectToSearchPage}
        />
        {this.hints.length > 0 && (
          <ul class={styles.hints}>
            {this.hints.map((product, i) => (
              <a
                href={`/details/${product.slug}`}
                class={styles.hintItem}
                onClick={(e: MouseEvent) =>
                  this.handleHintClick(e, product.slug)
                }
              >
                <li key={i}>{product.name}</li>
              </a>
            ))}
          </ul>
        )}
      </Container>
    )
  }
}
