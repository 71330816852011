import styles from './footer-mobile.css?module'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'

import { Container } from '~/components/layout/common/container'
import { COMPANY_CONTACTS } from '~/config/company-contacts'
import { inputToPhone } from '~/utils/format-phone'

@Component
export class FooterMobile extends VueComponent {
  render(): VNode {
    return (
      <Container class={styles.footerContainer}>
        <div class={styles.main}>
          <p>
            <a rel="nofollow" href={`tel:${COMPANY_CONTACTS.phone.mobile}`}>
              {inputToPhone(COMPANY_CONTACTS.phone.mobile)}
            </a>
          </p>
          <p>
            <a rel="nofollow" href={`tel:${COMPANY_CONTACTS.phone.main}`}>
              {inputToPhone(COMPANY_CONTACTS.phone.main)}
            </a>
          </p>
          <p><a href="mailto:zarukinav@bosco.ru">zarukinav@bosco.ru</a></p>
        </div>
        <div class={styles.contacts}>
          <p>Петровский Пассаж, Неглинная улица, 13, 1 линия, 1 этаж</p>
          <p>{COMPANY_CONTACTS.operationHours}</p>
        </div>
        {/* <div class={styles.social}> */}
        {/*  <p> */}
        {/*    Аптека Bosco */}
        {/*    <br />в социальных сетях */}
        {/*  </p> */}
        {/* </div> */}
        {/* <div class={styles.socialIcons}> */}
        {/*  <a */}
        {/*    href="https://www.facebook.com/BoscoApteka" */}
        {/*    target="_blank" */}
        {/*    rel="nofollow" */}
        {/*  > */}
        {/*    <img src={require('~/assets/icons/fb.svg')} /> */}
        {/*  </a> */}
        {/*  <a */}
        {/*    href="https://www.instagram.com/apteka_bosco/" */}
        {/*    target="_blank" */}
        {/*    rel="nofollow" */}
        {/*  > */}
        {/*    <img src={require('~/assets/icons/insta.svg')} /> */}
        {/*  </a> */}
        {/* </div> */}
      </Container>
    )
  }
}
